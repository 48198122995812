import React from "react";
import { SEO } from "../components/seo"

import Header from "../components/header";
import Hero from "../components/mainPage/hero";
import Solution from "../components/mainPage/solution";
import Benefits from "../components/mainPage/benefits";
import WhoIsItFor from "../components/mainPage/whoIsItFor";
import HowDoesItWork from "../components/mainPage/howDoesItWork";
import Faq from "../components/mainPage/faq";
import Footer from "../components/footer";

const IndexPage = () => (
  <div>
    <p>soon it will be</p>
    {/*<Header />
    <Hero />
    <Solution />
    <HowDoesItWork />
    <WhoIsItFor />
    <Benefits />
    <Faq />
<Footer />*/}
  </div>
);

export default IndexPage;

/*export const Head = () => (
  <SEO />
)*/
